body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.nav-pdfs {
  overflow-y: auto;
  height: 87vh;
}

.logo-nav {
  display: flex;
  align-items: center;
  margin: 20px;
}

.logo-nav img {
  max-width: 100px;
  height: auto;
  margin-right: 20px;
}

.logo-nav-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.logo-nav-text h1 {
  margin: 0;
  font-size: 12px;
  color: #15438c;
  text-transform: uppercase;
}

.app-bar {
  min-height: 64px;
}

.pdf-controls {
  position: fixed;

  right: 20px;
  top: 8em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 20px;
}

.pdf-controls button {
  margin-bottom: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading h1 {
  font-size: 24px;
  color: #15438c;
  text-transform: uppercase;
}

.MuiFab-primary {
  background-color: #15438c !important;
}

.MuiFab-secondary {
  background-color: rgb(161, 91, 62) !important;
}
